import Vue from "vue";
import VueRouter from "vue-router";

// Start:: Importing Middleware
import auth from "../middleware/auth.js";
// End:: Importing Middleware

// Start:: Importing Router Components
import Authentication from "../pages/Authentication.vue";
import AppContentWrapper from "../pages/AppContentWrapper.vue";
import HomePage from "../pages/HomePage.vue";
// End:: Importing Router Components

Vue.use(VueRouter);
const routes = [
  // ***************** Start:: Auth Routes Config
  {
    path: "/",
    component: Authentication,
    children: [
      {
        path: "/",
        component: () => import("@/views/Auth/LoginForm.vue"),
        name: "LoginForm",
      },
      {
        path: "/reset-password-email",
        name: "ResetPasswordEmailForm",
        component: () => import("@/views/Auth/ResetPasswordEmailForm.vue"),
      },
      {
        path: "/email-verification/:verification_type",
        name: "EmailVerificationForm",
        component: () => import("@/views/Auth/EmailVerificationForm.vue"),
      },
      {
        path: "/chang-phone-number",
        name: "ChangePhoneNumberForm",
        component: () => import("@/views/Auth/ChangePhoneNumberForm.vue"),
      },
      {
        path: "/reset-password",
        name: "ResetPasswordForm",
        component: () => import("@/views/Auth/ResetPasswordForm.vue"),
      },
    ],
  },
  // ***************** End:: Auth Routes Config

  // ***************** Start:: Content Routes Config
  {
    path: "/home",
    component: AppContentWrapper,
    children: [
      // Start:: Home Route Config
      {
        path: "/home",
        component: HomePage,
        meta: {
          middleware: [auth],
        },
      },
      // End:: Home Route Config

      // Start:: Countries Routes Config
      {
        path: "/countries",
        name: "CountriesHome",
        component: () => import("@/views/Cruds/Countries/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllCountries",
            component: () => import("@/views/Cruds/Countries/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateCountry",
            component: () => import("@/views/Cruds/Countries/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:id",
            name: "EditCountry",
            component: () => import("@/views/Cruds/Countries/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Countries Routes Config

      // Start:: Cities Routes Config
      {
        path: "/cities",
        name: "CitiesHome",
        component: () => import("@/views/Cruds/Cities/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllCities",
            component: () => import("@/views/Cruds/Cities/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateCity",
            component: () => import("@/views/Cruds/Cities/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:id",
            name: "EditCity",
            component: () => import("@/views/Cruds/Cities/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Cities Routes Config

      // Start:: Areas Routes Config
      {
        path: "/areas",
        name: "AreasHome",
        component: () => import("@/views/Cruds/Areas/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllAreas",
            component: () => import("@/views/Cruds/Areas/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateArea",
            component: () => import("@/views/Cruds/Areas/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:id",
            name: "EditArea",
            component: () => import("@/views/Cruds/Areas/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Areas Routes Config

      // Start:: Books Routes Config
      {
        path: "/Books",
        name: "BooksHome",
        component: () => import("@/views/Cruds/Books/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllBooks",
            component: () => import("@/views/Cruds/Books/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateBook",
            component: () => import("@/views/Cruds/Books/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:book_id/:book_slug",
            name: "ShowSingleBook",
            component: () => import("@/views/Cruds/Books/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:book_id/:book_slug",
            name: "EditBook",
            component: () => import("@/views/Cruds/Books/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Books Routes Config
      
      // Start:: Book Sections Routes Config
      {
        path: "/sections",
        name: "SectionsHome",
        component: () => import("@/views/Cruds/Books/BookSections/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "create",
            name: "CreateBookSection",
            component: () => import("@/views/Cruds/Books/BookSections/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:section_slug",
            name: "ShowSingleBookSection",
            component: () => import("@/views/Cruds/Books/BookSections/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:section_id/:section_slug",
            name: "EditBookSection",
            component: () => import("@/views/Cruds/Books/BookSections/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Book Sections Routes Config

      // Start:: Book Chapters Routes Config
      {
        path: "/chapters",
        name: "ChaptersHome",
        component: () => import("@/views/Cruds/Books/BookChapters/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "create",
            name: "CreateBookChapter",
            component: () => import("@/views/Cruds/Books/BookChapters/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:chapter_slug",
            name: "ShowSingleBookChapter",
            component: () => import("@/views/Cruds/Books/BookChapters/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:chapter_id/:chapter_slug",
            name: "EditBookChapter",
            component: () => import("@/views/Cruds/Books/BookChapters/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Book Chapters Routes Config

      // Start:: Book Lessons Routes Config
      {
        path: "/lessons",
        name: "LessonsHome",
        component: () => import("@/views/Cruds/Books/BookLessons/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "create",
            name: "CreateBookLesson",
            component: () => import("@/views/Cruds/Books/BookLessons/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:lesson_id/:lesson_slug",
            name: "ShowSingleBookLesson",
            component: () => import("@/views/Cruds/Books/BookLessons/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:lesson_id/:lesson_slug",
            name: "EditBookLesson",
            component: () => import("@/views/Cruds/Books/BookLessons/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Book Lessons Routes Config

      // Start:: Protocols Categories Routes Config
      {
        path: "/protocols-categories",
        name: "ProtocolsCategoriesHome",
        component: () => import("@/views/Cruds/ProtocolsCategories/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllProtocolsCategories",
            component: () => import("@/views/Cruds/ProtocolsCategories/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateProtocolCategory",
            component: () => import("@/views/Cruds/ProtocolsCategories/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:protocol_category_id/:protocol_category_uuid",
            name: "ShowSingleProtocolCategory",
            component: () => import("@/views/Cruds/ProtocolsCategories/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:protocol_category_id/:protocol_category_uuid",
            name: "EditProtocolCategory",
            component: () => import("@/views/Cruds/ProtocolsCategories/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Protocols Categories Routes Config

      // Start:: Protocols Routes Config
      {
        path: "/protocols",
        name: "ProtocolsHome",
        component: () => import("@/views/Cruds/ProtocolsCategories/Protocols/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "create",
            name: "CreateProtocol",
            component: () => import("@/views/Cruds/ProtocolsCategories/Protocols/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:protocol_slug",
            name: "ShowSingleProtocol",
            component: () => import("@/views/Cruds/ProtocolsCategories/Protocols/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:protocol_id/:protocol_slug",
            name: "EditProtocol",
            component: () => import("@/views/Cruds/ProtocolsCategories/Protocols/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Protocols Routes Config


            // Start:: Policies Categories Routes Config
            {
              path: "/policies-categories",
              name: "PoliciesCategoriesHome",
              component: () => import("@/views/Cruds/PoliciesCategories/Home.vue"),
              meta: {
                middleware: [auth],
              },
              children: [
                {
                  path: "all",
                  name: "AllPoliciesCategories",
                  component: () => import("@/views/Cruds/PoliciesCategories/ShowAll.vue"),
                  meta: {
                    middleware: [auth],
                  },
                },
                {
                  path: "create",
                  name: "CreatePolicyCategory",
                  component: () => import("@/views/Cruds/PoliciesCategories/Create.vue"),
                  meta: {
                    middleware: [auth],
                  },
                },
                {
                  path: "show/:policy_category_id/:policy_category_uuid",
                  name: "ShowSinglePolicyCategory",
                  component: () => import("@/views/Cruds/PoliciesCategories/Show.vue"),
                  props: true,
                  meta: {
                    middleware: [auth],
                  },
                },
                {
                  path: "edit/:policy_category_id/:policy_category_uuid",
                  name: "EditPolicyCategory",
                  component: () => import("@/views/Cruds/PoliciesCategories/Edit.vue"),
                  props: true,
                  meta: {
                    middleware: [auth],
                  },
                },
              ],
            },
            // End:: Protocols Categories Routes Config
      
            // Start:: Protocols Routes Config
            {
              path: "/policies",
              name: "PoliciesHome",
              component: () => import("@/views/Cruds/PoliciesCategories/Policies/Home.vue"),
              meta: {
                middleware: [auth],
              },
              children: [
                {
                  path: "create",
                  name: "CreatePolicy",
                  component: () => import("@/views/Cruds/PoliciesCategories/Policies/Create.vue"),
                  meta: {
                    middleware: [auth],
                  },
                },
                {
                  path: "show/:policy_slug",
                  name: "ShowSinglePolicy",
                  component: () => import("@/views/Cruds/PoliciesCategories/Policies/Show.vue"),
                  props: true,
                  meta: {
                    middleware: [auth],
                  },
                },
                {
                  path: "edit/:policy_id/:protocol_slug",
                  name: "EditPolicy",
                  component: () => import("@/views/Cruds/PoliciesCategories/Policies/Edit.vue"),
                  props: true,
                  meta: {
                    middleware: [auth],
                  },
                },
              ],
            },
            // End:: Protocols Routes Config

      // Start:: Educations Routes Config
      {
        path: "/educations",
        name: "EducationsHome",
        component: () => import("@/views/Cruds/Educations/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllEducations",
            component: () => import("@/views/Cruds/Educations/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateEducation",
            component: () => import("@/views/Cruds/Educations/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:id",
            name: "EditEducation",
            component: () => import("@/views/Cruds/Educations/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Educations Routes Config

      // Start:: Specialties Routes Config
      {
        path: "/specialties",
        name: "SpecialtiesHome",
        component: () => import("@/views/Cruds/Specialties/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllSpecialties",
            component: () => import("@/views/Cruds/Specialties/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateSpecialty",
            component: () => import("@/views/Cruds/Specialties/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:id",
            name: "EditSpecialty",
            component: () => import("@/views/Cruds/Specialties/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Specialties Routes Config

      // Start:: PowerPoints Routes Config
      {
        path: "/power-points",
        name: "PowerPointsHome",
        component: () => import("@/views/Cruds/PowerPoints/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllPowerPoints",
            component: () => import("@/views/Cruds/PowerPoints/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreatePowerPoint",
            component: () => import("@/views/Cruds/PowerPoints/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:uuid",
            name: "ShowSinglePowerPoint",
            component: () => import("@/views/Cruds/PowerPoints/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:id/:uuid",
            name: "EditPowerPoint",
            component: () => import("@/views/Cruds/PowerPoints/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: PowerPoints Routes Config

      // Start:: Mcqs Routes Config
      {
        path: "/mcqs",
        name: "McqsHome",
        component: () => import("@/views/Cruds/Mcqs/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllMcqs",
            component: () => import("@/views/Cruds/Mcqs/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateMcq",
            component: () => import("@/views/Cruds/Mcqs/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:id",
            name: "EditMcq",
            component: () => import("@/views/Cruds/Mcqs/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Mcqs Routes Config

      // Start:: Lectures Routes Config
      {
        path: "/lectures",
        name: "LecturesHome",
        component: () => import("@/views/Cruds/Lectures/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllLectures",
            component: () => import("@/views/Cruds/Lectures/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateLecture",
            component: () => import("@/views/Cruds/Lectures/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:uuid",
            name: "ShowSingleLecture",
            component: () => import("@/views/Cruds/Lectures/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:id/:uuid",
            name: "EditLecture",
            component: () => import("@/views/Cruds/Lectures/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Lectures Routes Config

      // Start:: Articles Categories Routes Config
      {
        path: "/articles-categories",
        name: "ArticlesCategoriesHome",
        component: () => import("@/views/Cruds/ArticlesCategories/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllArticlesCategories",
            component: () => import("@/views/Cruds/ArticlesCategories/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateArticleCategory",
            component: () => import("@/views/Cruds/ArticlesCategories/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:article_category_id/:article_category_uuid",
            name: "ShowSingleArticleCategory",
            component: () => import("@/views/Cruds/ArticlesCategories/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:article_category_id/:article_category_uuid",
            name: "EditArticleCategory",
            component: () => import("@/views/Cruds/ArticlesCategories/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Articles Categories Routes Config

      // Start:: Articles Routes Config
      {
        path: "/articles",
        name: "ArticlesHome",
        component: () => import("@/views/Cruds/ArticlesCategories/Articles/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "create",
            name: "CreateArticle",
            component: () => import("@/views/Cruds/ArticlesCategories/Articles/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:article_uuid",
            name: "ShowSingleArticle",
            component: () => import("@/views/Cruds/ArticlesCategories/Articles/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:article_id/:article_uuid",
            name: "EditArticle",
            component: () => import("@/views/Cruds/ArticlesCategories/Articles/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Articles Routes Config

      // Start:: Roles Routes Config
      {
        path: "/roles",
        name: "RolesHome",
        component: () => import("@/views/Cruds/Roles/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllRoles",
            component: () => import("@/views/Cruds/Roles/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateRole",
            component: () => import("@/views/Cruds/Roles/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:id",
            name: "EditRole",
            component: () => import("@/views/Cruds/Roles/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Roles Routes Config

      // Start:: Admins Routes Config
      {
        path: "/admins",
        name: "AdminsHome",
        component: () => import("@/views/Cruds/Admins/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllAdmins",
            component: () => import("@/views/Cruds/Admins/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateAdmin",
            component: () => import("@/views/Cruds/Admins/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:id",
            name: "EditAdmin",
            component: () => import("@/views/Cruds/Admins/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      // End:: Admins Routes Config

      // Start:: Users Routes Config
      {
        path: "/users",
        name: "UsersHome",
        component: () => import("@/views/Cruds/Users/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllUsers",
            component: () => import("@/views/Cruds/Users/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "create",
            name: "CreateUser",
            component: () => import("@/views/Cruds/Users/Create.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "edit/:id",
            name: "EditUser",
            component: () => import("@/views/Cruds/Users/Edit.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          },
          ,
          {
            path: "show/:user_id",
            name: "ShowSingleUser",
            component: () => import("@/views/Cruds/Users/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          }
        ],
      },
      // End:: Users Routes Config
      

      // Start:: Users Routes Config
      {
        path: "/events",
        name: "EventsHome",
        component: () => import("@/views/Cruds/Event/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllEvents",
            component: () => import("@/views/Cruds/Event/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:event_id",
            name: "ShowSingleEvents",
            component: () => import("@/views/Cruds/Event/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          }
        ],
      },
      // End:: Users Routes Config
      
      
      
      // Start:: ContactUs Routes Config
      {
        path: "/contact-us",
        name: "ContactUsHome",
        component: () => import("@/views/Cruds/ContactUs/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllContactUs",
            component: () => import("@/views/Cruds/ContactUs/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:contact_uuid",
            name: "ShowSingleContact",
            component: () => import("@/views/Cruds/ContactUs/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          }
        ],
      },
      // End:: ContactUs Routes Config
      

      // Start:: Sponsors Routes Config
      {
        path: "/sponsors",
        name: "SponsorsHome",
        component: () => import("@/views/Cruds/Sponsors/Home.vue"),
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllSponsors",
            component: () => import("@/views/Cruds/Sponsors/ShowAll.vue"),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "show/:sponsor_id",
            name: "ShowSingleSponsor",
            component: () => import("@/views/Cruds/Sponsors/Show.vue"),
            props: true,
            meta: {
              middleware: [auth],
            },
          }
        ],
      },
      // End:: Sponsors Routes Config
      
    
      // Start:: All Notifications Route Config
      {
        path: "/all-notifications",
        name: "AllNotifications",
        component: () => import("@/views/Notifications/AllNotifications.vue"),
        meta: {
          middleware: [auth],
        },
      },
      // End:: All Notifications Route Config
    ],
  },
  // ***************** End:: Content Routes Config

  // ***************** Start:: Forbidden Route Config
  {
    path: "/forbidden",
    name: "ForbiddenPage",
    component: () => import("@/pages/ForbiddenPage.vue"),
  },
  // ***************** End:: Forbidden Route Config

  // ***************** Start:: Not Found Route Config
  {
    path: "/:notFound(.*)",
    name: "NotFoundPage",
    component: () => import("@/pages/NotFound.vue"),
  },
  // ***************** End:: Not Found Route Config
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
  routes,
});

// Start:: Middleware And Router Guards
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  } else if (localStorage.getItem("urc_dashboard_user_token")) {
    if (
      to.name == "LoginForm" ||
      to.name == "ResetPasswordPhoneNumberForm" ||
      to.name == "PhoneVerification" ||
      to.name == "ChangePhoneNumberForm" ||
      to.name == "ResetPasswordForm"
    ) {
      return next("/home");
    } else {
      return next();
    }
  }
  return next();
});
// End:: Middleware And Router Guards

export default router;