<template>
  <div class="home_page_wrapper">
    <div class="row">
        <div class="col-md-4"  v-for="state in states" :key="state.id">
          <StateCard  :key="state.id" :icon="state.icon" :text="state.text" :count="state.count"  :up="state.up" /> 
        </div>
    </div>
  </div>
</template>

<script>
// Start:: Importing Charts
import StateCard from "@/components/ui/cards/StateCard.vue";
// End:: Importing Charts

export default {
  name: "HomePage",

  components: {
    StateCard,
  },
  data(){
    return{
      pageIsLoading:false,
      states:[
      
      ],

    }
  },
  methods: {
    // START:: FILTER STATISTICS
    
    // END:: FILTER STATISTICS
    async setPageData() {
      this.pageIsLoading = true;
      try {
        let res = await this.$axios({
          method: "GET",
          url: "home",
        });
        this.pageIsLoading = false;
        // console.log(res.data.data);
       this.states          = res.data.data.cards_data;
      } catch (error) {
        this.pageIsLoading = false;
        console.log(error.response.data.message);
      }
    },

    kFormatter(num) {
      return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    }
  },

  mounted() {
    // START:: FIRE METHODS
    this.setPageData();
    // END:: FIRE METHODS
  },
};
</script>

<style lang="scss" scoped>
.home_page_wrapper {
  position: relative;
}


.card{
  background-color: #fff;
  margin: 10px 5px;
  min-height: 400px;
  box-shadow: 5px 5px 5px #c1c1c1ba;
  border-radius: 25px;
  border: 0;
}

.state-heading{
  font: normal normal normal 18px/23px Overpass;
  color: #147CCC;
  margin-bottom: 10px;
}
.visits_count{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
}
.visits_count p{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
}

.visits_count p span.count{
  font-size: 23px;
}
.progress{
  display: flex;
  list-style: none;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  background-color: #fff;
}

.progress li{
  display: flex;
  list-style: none;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
}

.progress li progress{
  height: 50%;
  border-radius: 20px;
}
.progress li progress::-webkit-progress-bar{
  background-color: #ffff;
}
.progress li:nth-child(odd) progress::-webkit-progress-value{
  height: 70%;
  background-color: #147CCC;
}
.progress li:nth-child(even) progress::-webkit-progress-value{
  height: 70%;
  background-color: #03847D;
}
table{
  width: 100%;
  height: 80%;
  margin-left: 10px;
}
table thead{
  color: #147CCC;
}
table tbody tr td{
  color: #0A2C75;
}
table tr{
  border-radius: 8px;
}
table tr:nth-child(even){
  background-color: #F4F7FB;
}

</style>